.dashboard-card {
  margin-right: 10px;
  height: 180px !important;
}

.dashboard-bottom-header {
  display: none !important;
}

.dashboard-company {
  width: 25%;
  height: 27px;
}

.desktop-dashboard-company {
  padding: 0 5px;
}

.mobile-dashboard-company-logos {
  display: none;
}

.section-divider {
  width: 70px;
  height: 0px;
  border: 0.5px solid #d0dfe6;
  transform: rotate(90deg);
}

.invest-button-orange img {
  width: 20px;
  height: 20px;
}

.invest-button-orange span {
  font-weight: 400;
  font-size: 14px;
  font-family: Poppins;
}

.potential-values-card {
  width: 203px;
  height: 73px;
  background: #ffffff;
  border: 0.683856px solid #d0dfe6;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .flag {
    width: 49px;
    height: 29px;
  }

  .card-details {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .card-title-blue {
      font-weight: 400;
      height: 28px;
      font-size: 18px;
      line-height: 119%;
      letter-spacing: -0.04em;
      color: #0071ce;
    }

    .card-title-black {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 119%;
      letter-spacing: -0.03em;
      color: #3f4254;
    }
  }
}

.title-size {
  font-size: 18px;
}

.values-size {
  font-size: 25px;
}

.dashboard-company-logos-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.licensed-content {
  flex: 1 1 0px;
  text-align: justify;
}

.buy-button {
  background-color: $light-blue-100;
  color: $primary-blue;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.dashboard-card-blue-background-color {
  background: $primary-blue !important;
}

.dashboard-card-white-background-color {
  background: $primary-white !important;
}

.dashboard-card-gray-background-color {
  background: $dark-gray-500 !important;
}

.welcome-text-color {
  color: $primary-blue;
}

.user-first-name {
  font-size: 27px;
}

.dashboard-horizontal-line {
  border-bottom: 1px solid $light-blue;
}

.gray-title {
  color: $dark-gray-600;
}

.white-title {
  color: $primary-white;
}

.sub-text {
  color: $primary-white;
  line-height: 15px;
  font-size: 13px;
}

.dashboard-card-image {
  width: 100%;
  height: 100%;
}

.dashboard-card-x-small-text {
  font-size: 14px;
  margin-top: 5px;
}

.potential-currencies {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.fund-plans {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 10px;

  .promo-card {
    width: 311px;
    height: 165px;
    border-radius: 7.85263px;
    cursor: pointer;
  }
}

.promo {
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
  gap: 13px;
  margin-top: 21px;
  padding-bottom: 15px;
  overflow-x: scroll;

  .promo-card {
    width: 50%;
    height: 141px;
    border-radius: 7.85263px;
    cursor: pointer;
  }

  .promo-image {
    width: 100%;
    height: 141px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.card-flag {
  width: 20px;
  height: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.portfolio-value-and-pocket-container {
  height: 33px;
}

.pocket-balance {
  margin-left: 18px;
}

.portfolio-value-balance {
  margin-left: 11px;
}

.image-size {
  max-width: 184px;
  max-height: 135px;
}

.timeline-vertical-line {
  width: 1.5px;
  height: 27px;
  border-radius: 4px;
  margin-left: 10px;
  margin-bottom: 2px
}

.timeline-container .timeline-vertical-line:last-child {
  display: none;
}

.transactions-timeline-container > div:last-child .timeline-horizontal-line {
  display: none;
}

.dashboard-asset-card {
  min-width: 336px;
  max-width: 336px;

  .portfolio-value {
    font-size: 32px;
  }

  .portfolio-value-label {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .dashboard-company-logos-container {
    flex-direction: column;
  }

  .dashboard-company-logos {
    width: 100%;
  }

  .licensed-content {
    margin-left: 0;
  }

  .portfolio-icon {
    padding-top: 2px;
    font-size: 2.5rem !important;
    font-size: 2.5rem !important;
  }
}

@media (max-width: 667px) {
  .mobile-dashboard-company-logos {
    display: block;
    margin-top: 10px;
    background-color: $primary-white;
    border-radius: 5px;
  }
}

@media (max-width: 667px) {
  .mobile-dashboard-company-logos {
    display: block;
    margin-top: 10px;
    background-color: $primary-white;
    border-radius: 5px;
  }
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }

  .promo {
    flex-direction: column;
    align-items: center;

    .promo-card {
      width: 100%;
      height: 148.59px;
    }
  }

  .hide-icon {
    display: none;
  }

  .portfolio-value {
    margin-right: 0px !important;
  }

  .checkIT {
    width: 367px;
  }
}

@media (max-width: 594px) {
  .dashboard-card {
    margin-right: 0 !important;
  }
}

@media (max-width: 540px) {
  .dashboard-bottom-header {
    display: block !important;
  }

  .btn-special {
    width: 90%;
  }
}

@media (max-width: 400px) {
  .dashboard-asset-card {
    min-width: 290px;
    max-width: 290px;

    .portfolio-value {
      font-size: 24px;
    }
  
    .portfolio-value-label {
      font-size: 13px;
    }
  }
}

@media (max-width: 435px) {
  .dashboard-image-mobile {
    width: 80px;
    height: 62px;
  }

  .title-size {
    font-size: 15px;
  }

  .values-size {
    font-size: 14px;
  }
}

@media (max-width: 399px) {
  .dashboard-card {
    height: auto !important;
  }
}
