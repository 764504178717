.portfolio-donut-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;

  .portfolio-content {
    background: $light-blue-40;
    max-width: 49.5%;
  }

  .pie-chart-wrapper {
    display: flex;
  }

  .pie-chart-info {
    display: flex;
    flex-direction: column;
    padding-left: 0px !important;
    width: fit-content;
    padding-top: 15px;

    ul {
      overflow: scroll;
    }

    li {
      font-weight: 600;
      font-size: 11px;
      line-height: 121%;
      /* or 16px */
      letter-spacing: -0.03em;
      color: #3f4254;
      list-style: none;
      margin-bottom: 4px;
      padding-bottom: 5px;

      .asset-name {
        text-transform: capitalize;
      }
      .asset-name-amount {
        padding-left: 13.94px;
      }
    }
  }
}

.edit-recurring-payment-wrapper {
  position: relative;
  cursor: pointer;
}

.edit-recurring-payment-icon { 
  width: 24px ;
  height: 24px;
}

.edit-recurring-payment-card {
  position: absolute;
  right: 1.9rem;
  border-radius: 8px;
  background-color: #FFFFFF !important;
  display: inline-block;
  padding: 1rem;
  color: black;
  margin-top: 6rem;
  justify-content: flex-end;
  width: 342px;
  z-index: 10000;
  cursor: pointer;
  text-align: left;
  font-weight: 400;
  font-family: Poppins;
}

.plan-subscription-details-container .blue-separator:last-child{
  display: none;
}


.current-value {
  width: 75%;
}


@media (width <= 450px) {
  .current-value {
    width: 90%;
  }
}


@media (max-width: 480px) {
  .pie-chart-info {
    margin-left: 25px;
  }
  .edit-recurring-payment-card{ 
  width: 50%;
  }
  .edit-recurring-payment-icon { 
  width: 20%;
  margin-left: 0.7rem !important;
}
}

@media (max-width: 767px) {
  .portfolio-donut-section {
    display: flex;
    flex-direction: column;

    .portfolio-content {
      max-width: 100%;
    }
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .portfolio-donut-section {
    .card-content-portfolio {
      .pie-chart-container-portfolio {
        flex-direction: column;
      }
    }
  }
}

