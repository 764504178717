@import "./variables";

@import "./app/modules/Invest/RecommendedPlans.scss";

// LAYOUT
@import "./_metronic/layout/components/aside/index.scss";
@import "./_metronic/layout/components/footer/index.scss";
@import "./_metronic/layout/components/header-mobile/index.scss";
@import "./_metronic/layout/components/header/index.scss";
@import "./_metronic/layout/components/extras/offcanvas/index.scss";
@import "./_metronic/layout/components/index.scss";
@import "./_metronic/layout/components/brand/index.scss";

// DASHBOARD
@import "./app/modules/Common/buttons/index.scss";
@import "./app/modules/Dashboard/pages/dashboardCard.scss";
@import "./app/modules/Dashboard/pages/fundCard.scss";

// MODULES
@import "./app/modules/Portfolio/pages/index.scss";
@import "./app/modules/Pocket/index.scss";
@import "./app/modules/Management/styles.scss";

// COMPONENTS
@import "./app/modules/Common/PaymentChannel/index.scss";
@import "./app/modules/Invest/components/PayWithBongaPoints/index.scss";
@import "./app/modules/Common/WarningMessage/index.scss";
@import "./app/modules/Common/RadioButton/index.scss";
@import "./app/modules/KYC/Components/index.scss";
@import "./app/modules/Pocket/components/MainWrapper.scss";
@import "./app/modules/Portfolio/components/portfolio.scss";
@import "./app/modules/Account/components/RiskProfileDisplay/index.scss";


// BACKGROUND COLORS
.bg-transparent {
    background: transparent;
}

.bg-white {
    background-color: $primary-white;
}

.bg-second-white {
    background-color: $second-white !important;
}

.bg-white-500 {
    background-color: $white-500;
}

.bg-white-300 {
    background-color: $white-300;
}

.bg-white-200 {
    background-color: $white-200;
}

.bg-white-100 {
    background-color: $white-100;
}

.bg-gray-550 {
    background-color: $gray-550;
}

.bg-gray-400 {
    background: $gray-400 !important;
}

.bg-gray-430 {
    background: $gray-430 !important;
}

.bg-dark-blue-700 {
    background: $dark-blue-700 !important;
}

.bg-gray-550 {
    background-color: $gray-550;
}

.bg-gray-550 {
    background-color: $gray-550;
}

.bg-gray-500 {
    background-color: $gray-500 !important;
}

.bg-gray-450 {
    background-color: $gray-450;
}

.bg-dark-gray-400 {
    background-color: $dark-gray-400;
}

.bg-light-blue {
    background-color: $light-blue-50;
}

.bg-light-blue-600 {
    background-color: $light-blue-600;
}

.bg-light-blue-40 {
    background-color: $light-blue-40;
}

.bg-light-blue-540 {
    background-color: $light-blue-540;
}

.bg-light-blue-530 {
    background-color: $light-blue-530;
}

.bg-light-blue-455 {
    background-color: $light-blue-455;
}

.bg-light-blue-450 {
    background-color: $light-blue-450;
}

.bg-light-blue-400 {
    background-color: $light-blue-400;
}

.bg-light-blue-200 {
    background-color: $light-blue-200 !important;
}

.bg-light-blue-250 {
    background-color: $light-blue-250;
}

.bg-light-blue-200 {
    background-color: $light-blue-200 !important;
}

.bg-light-blue-50 {
    background-color: $light-blue-50;
}

.bg-light-blue-60 {
    background-color: $light-blue-60;
}

.bg-light-blue-100 {
    background-color: $light-blue-100;
}

.bg-blue {
    background-color: $primary-blue !important;
}

.bg-primary-orange {
    background-color: $primary-orange;
}

.bg-second-orange {
    background-color: $second-orange;
}

.bg-orange-300 {
    background-color: $orange-200;
}

.bg-orange-200 {
    background-color: $orange-300;
}

.bg-orange-150 {
    background-color: $orange-150;
}

.bg-orange-100 {
    background-color: $orange-100;
}

.bg-orange-90 {
    background-color: $orange-90;
}

.bg-red-600 {
    background-color: $red-600;
}

.bg-second-green {
    background-color: $second-green;
}

.bg-black-655 {
    background-color: $black-655;
}

// FONT COLORS

.text-blue {
    color: $primary-blue;
}

.text-dark-blue-800 {
    color: $dark-blue-800;
}

.text-dark-blue-700 {
    color: $dark-blue-700;
}

.text-dark-blue {
    color: $dark-blue;
}

.text-dark-blue-600 {
    color: $dark-blue-600;
}

.text-light-blue-600 {
    color: $light-blue-600;
}

.text-white {
    color: $primary-white;
}

.text-second-red {
    color: $second-red;
}

.text-red-700 {
    color: $red-700;
}

.text-red-600 {
    color: $red-600;
}

.text-red-500 {
    color: $red-500;
}

.text-red-350 {
    color: $red-350;
}

.text-red-200 {
    color: $red-200;
}

.text-gray-250 {
    color: $gray-250;
}

.text-gray-300 {
    color: $gray-300;
}

.text-gray-500 {
    color: $gray-500;
}

.text-gray-440 {
    color: $gray-440;
}

.text-dark-gray-550 {
    color: $dark-gray-550
};

.text-dark-gray-300 {
    color: $dark-gray-300
};

.text-dark-gray-200 {
    color: $dark-gray-200
};

.text-light-gray-600 {
    color: $light-gray-600;
}

.text-gray-600 {
    color: $gray-600;
}

.text-gray-700 {
    color: $gray-700;
}

.text-green {
    color: $primary-green;
}

.text-second-green {
    color: $second-green;
}

.text-green-800 {
    color: $green-800;
}

.text-primary-orange {
    color: $primary-orange;
}

.text-second-orange {
    color: $second-orange;
}

.text-orange-350 {
    color: $orange-350;
}

.text-orange-650 {
    color: $orange-650;
}

.text-dark-700 {
    color: $dark-gray-700
}

.text-dark-600 {
    color: $dark-gray-600
}

.text-dark-550 {
    color: $dark-gray-550
}

.text-dark-450 {
    color: $dark-gray-450
}

.text-orange-400 {
    color: $orange-400;
}

.text-dark-350 {
    color: $dark-gray-350
}

.text-black-700 {
    color: $black-700
}

.text-black-650 {
    color: $black-650
}

.text-black-600 {
    color: $black-600
}

.text-yellow {
    color: $yellow
}

.text-primary-black {
    color: $primary-black
}

// FONT SIZES

.text-8 {
    font-size: 8px;
}

.text-7 {
    font-size: 7px;
}

.text-6 {
    font-size: 6px;
}

.text-9 {
    font-size: 9px;
}

.text-10 {
    font-size: 10px;
}

.text-11 {
    font-size: 11px;
}

.text-12 {
    font-size: 12px;
}

.text-13 {
    font-size: 13px !important;
}

.text-14 {
    font-size: 14px;
}

.text-15 {
    font-size: 15px;
}

.text-16 {
    font-size: 16px;
}

.text-17 {
    font-size: 17px;
}

.text-18 {
    font-size: 18px;
}

.text-20 {
    font-size: 20px;
}

.text-23 {
    font-size: 23px;
}

.text-25 {
    font-size: 25px;
}

.text-29 {
    font-size: 29px;
}

.text-30 {
    font-size: 30px;
}

.text-33 {
    font-size: 33px;
}

.text-45 {
    font-size: 45px;
}

// FONT WEIGHT

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

// LINE HEIGHT

.line-height-14 {
    line-height: 14px;
}

.line-height-17 {
    line-height: 17px;
}

.line-height-18 {
    line-height: 18px;
}

.line-height-19 {
    line-height: 19px;
}

.line-height-21 {
    line-height: 21px;
}

.line-height-23 {
    line-height: 23px;
}

.line-height-30 {
    line-height: 30px;
}

.line-height-35 {
    line-height: 35px;
}

// LETTER SPACING

.letter-spacing--1 {
    letter-spacing: -1px;
}

.letter-spacing--08 {
    letter-spacing: -0.8px;
}

// BORDERS

.border-light-blue {
    border: 0.582px solid $light-blue !important
}

.border-light-blue-550 {
    border: 0.582px solid $light-blue-550 !important
}

.border-light-blue-500 {
    border: 0.582px solid $light-blue-500 !important
}

.border-light-blue-460 {
    border: 1px solid $light-blue-460 !important
}

.border-light-blue-150 {
    border: 0.84px solid $light-blue-200;
}

.border-1-light-blue-150 {
    border: 1px solid $light-blue-200;
}

.border-1-blue {
    border: 1px solid $primary-blue !important;
}

.border-1-right-blue {
    border-right: 0.84px solid $primary-blue;
}

.border-light-blue-600 {
    border: 1px solid $light-blue-600 !important;
}

.border-red-300 {
    border: 1px solid $red-300;
}

.border-primary-orange {
    border: 1px solid $primary-orange;
}

.border-orange-600 {
    border: 0.267px solid $orange-600;
}

.border-orange-500 {
    border: 1px solid $orange-500;
}

.border-orange-400 {
    border: 0.267px solid $orange-400;
}

.border-1-orange-100 {
    border: 1px solid $orange-100;
}

.border-light-blue-100 {
    border: 0.5px solid $light-blue-100;
}

.border-light-blue-400 {
    border: 0.5px solid $light-blue-400;
}

.border-light-blue-600 {
    border: 1px solid $light-blue-600 !important;
}

.border-light-blue-650 {
    border: 1px solid $light-blue-650 !important;
}

.border-light-blue-540 {
    border: 0.5px solid $light-blue-540;
}

.border-b-light-blue-400 {
    border-bottom: 0.379px solid $light-blue-400;
}

.border-2-light-blue-50 {
    border-bottom: 2px solid $light-blue-50;
}

.border-light-blue-350 {
    border: 1px solid $light-blue-350;
}

.border-b-light-blue-400 {
    border-bottom: 0.379px solid $light-blue-400;
}

.border-1-gray-550 {
    border-bottom: 1px solid $gray-550;
}

.border-1-gray-350 {
    border: 1px solid $gray-350;
}

.border-1-gray-600 {
    border: 1px solid $gray-600;
}

.border-light-gray-540 {
    border: 0.644px solid $light-blue-540 !important;
}

.border-b-light-gray-540 {
    border-bottom: 0.5px solid $light-blue-540 !important;
}

.border-b-light-gray-540 {
    border-bottom: 0.5px solid $light-blue-540 !important;
}

.border-b-gray {
    border-bottom: 1px solid $gray !important;
}

.border-r-gray {
    border-right: 1px solid $gray !important;
}

.border-gray {
    border: 1px solid $gray !important;
}

.border-b-1-gray-600 {
    border-bottom: 1px solid $dark-gray-600;
}

.border-1-primary-blue {
    border: 1px solid $primary-blue !important;
}

.border-1-blue-sky-400 {
    border: 1px solid $blue-sky-400 !important;
}

.border-1-light-blue-460 {
    border: 1px solid $light-blue-460 !important;
}

.border-light-blue-545 {
    border: 1px solid $light-blue-545 !important;
}

.border-b-light-blue-545 {
    border-bottom: 1px solid $light-blue-545 !important;
}

.border-b-1-primary-blue {
    border-bottom: 1px solid $primary-blue !important;
}

.border-second-orange {
    border: 1px solid $second-orange;
}

.border-r-gray-450 {
    border-right: 1px solid $dark-gray-450;
}

.border-gray-450 {
    border: 1px solid $dark-gray-450;
}

.border-b-white-200 {
    border-bottom: 1px solid $white-200;
}

.border-b-white-450 {
    border-bottom: 0.5px solid $white-450;
}

.border-white-50 {
    border: 1px solid $white-50;
}

// BOX-SHADOW

.box-shadow-black-900 {
    box-shadow: 0px 2px 10px 0px $black-800;
}

.box-shadow-black-550 {
    box-shadow: 0px 0px 10px 0px $black-550;
}

.box-shadow-black-750 {
    box-shadow: 0px 0px 10px 0px $black-750;
}

// BORDER-RADIUS

.border-radius-2 {
    border-radius: 2px;
}

.border-radius-3 {
    border-radius: 3px;
}

.border-radius-4 {
    border-radius: 4px;
}

.border-radius-5 {
    border-radius: 5px;
}

.border-radius-6 {
    border-radius: 6px;
}

.border-radius-7 {
    border-radius: 7px;
}

.border-radius-8 {
    border-radius: 8px;
}

.border-radius-9 {
    border-radius: 9px;
}

.border-radius-10 {
    border-radius: 10px;
}

.border-radius-12 {
    border-radius: 12px;
}

.border-radius-16 {
    border-radius: 16px;
}

.border-radius-20 {
    border-radius: 20px;
}

.border-radius-24 {
    border-radius: 24px;
}

.border-radius-31 {
    border-radius: 31px;
}

.border-radius-circle {
    border-radius: 50%;
}

// WIDTH

.width-342 {
    width: 342px;
}

.width-319 {
    width: 360px;
}

.width-345 {
    width: 345px;
}

.width-303 {
    width: 303px;
}

.width-336 {
    min-width: 300px;
    max-width: 300px;
}

.width-275 {
    width: 275px;
}

.width-140 {
    width: 140px;
}

.width-170 {
    width: 170px;
}

.width-190 {
    width: 190px;
}

.width-220 {
    width: 220px;
}

.width-123 {
    width: 123px;
}

.width-94 {
    width: 94%;
}

.width-85 {
    width: 85%;
}

.width-60 {
    width: 60%;
}

.width-95 {
    width: 95%;
}

.width-45 {
    width: 45%;
}

.width-40 {
    width: 40%;
}

.width-30 {
    width: 30px;
}

.width-fit-content {
    width: fit-content;
}

// HEIGHT

.height-45 {
    height: 45px;
}

.height-40 {
    height: 40px;
}

.height-35 {
    height: 35px;
}

.height-18 {
    height: 18px;
}

// GAP

.gap-40 {
    gap: 40px;
}

.gap-25 {
    gap: 25px;
}

select:disabled {
    color: $white-200;
}

.blue-button-hover:hover {
    background-color: $dark-blue-300 !important;
}

.orange-button-hover:hover {
    background-color: $orange-250 !important;
}

.white-button-hover:hover {
    background-color: $gray-400 !important;
}

input:disabled {
    border: 1px solid $gray;
    color: $gray-300 !important;
    background-color: $white-200 !important;
    font-weight: 500;
}

#remove-disabled-style {
    border: 1px solid $dark-gray-450;
    color: $dark-gray-600 !important;
    background-color: transparent !important;
    font-weight: 500;
}

.button-sm {
    width: 140px;
}

.button-w-121 {
    width: 121px;
}

.button-w-360 {
    width: 360px;
}

.button-w-265 {
    width: 265px;
}

.bottom-10 {
    bottom: 10px;
}

.z-index-1 {
    z-index: 1;
}

.spinner.spinner-black:before {
    border: 2px solid  $black-600 !important;
    border-right: 2px solid transparent !important;
}

.spinner.spinner-blue:before {
    border: 2px solid  $primary-blue !important;
    border-right: 2px solid transparent !important;
}

.currency-flag {
    width: 28px;
    height: 20px;
    border-radius: 2px
}

.text-decoration-underline {
    text-decoration: underline;
}

.tooltip-inner {
    max-width: 300px;
}

@media (width <= 768px) {
    .text-md-13 {
        font-size: 10px;
    }

    .text-md-18 {
        font-size: 16px;
    }

    .text-md-16 {
        font-size: 14px;
    }

    .button-md-w-121 {
        width: 103px;
    }

    .button-md-w-360 {
        width: 100%;
    }
}

@media screen and (max-width: 482px) {
    .width-303 {
        width: 100%;
    }

    .width-319 {
        width: 100%;
    }

    .width-275 {
        width: 100%;
    }

    .width-220 {
        width: 100%;
    }

    .text-sm-15 {
        font-size: 11px;
    }

    .button-lg {
        width: 100%
    }

    .text-md-16 {
        font-size: 13px !important;
    }

    .width-345 {
        width: 100%;
    }
}

@media screen and (max-width: 350px)  {
    .width-342 {
        width: 100%;
    }
}


// MOVE UP CHAT BOT ICON
@media screen and (max-width: 991px) {
    .zsiq-float {
        bottom: 55px !important;
    }
}

.border-1-light-blue-150 {
    border: 1px solid $light-blue-200;
}

.border-radius-16 {
    border-radius: 16px;
}